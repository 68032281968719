
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { setItem, getItem } from '../../utils/localstorage';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';

@Component({
  components: {
    EpMaterialIcon,
  },
})
export default class EpCollapse extends Vue {
  @Prop({ default: true })
  private expandedByDefault!: boolean;

  @Prop({ default: false })
  private disableHeader!: boolean;

  @Prop({ default: true })
  private usePadding!: boolean;

  @Prop({ default: '' })
  private tyyppi!: string;

  @Prop({ default: false })
  private borderTop!: boolean;

  @Prop({ default: true })
  private borderBottom!: boolean;

  @Prop({ default: 'right' })
  private chevronLocation!: 'right' | 'left';

  @Prop({ default: true })
  private collapsable!: boolean;

  @Prop({ default: false })
  private first!: boolean;

  @Prop({ default: false, type: Boolean })
  private blue!: Boolean;

  @Prop({ required: false, default: false })
  private shadow!: boolean;

  @Prop({ required: false, default: false })
  private togglefull!: boolean;

  private toggled = false;

  get hasHeaderSlot() {
    return !!this.$scopedSlots.header;
  }

  get styles() {
    let style = {
      header: {},
      collapse: {},
    };

    if (this.blue) {
      style = {
        header: {
          'color': '#001A58',
        },
        collapse: {
          'padding': '20px 20px 0px 20px',
          'border-radius': '30px',
          'border': '1px solid #C8F1FF',
          'background': '#E6F6FF',
        },
      };
    }

    if (this.usePadding) {
      style = {
        header: {
          ...style.header,
          'margin-bottom': '10px',
          'margin-top': '10px',
        },
        collapse: {
          ...style.collapse,
          'padding-top': '20px',
          'padding-bottom': '20px',
        },
      };
    }

    return style;
  }

  get classess() {
    let result = 'ep-collapse';
    if (this.borderTop) {
      result += ' topborder';
    }

    if (this.shadow) {
      result += ' shadow-tile';
    }

    if (this.togglefull) {
      result += ' togglefull';
    }

    return result;
  }

  isToggled() {
    try {
      if (this.tyyppi) {
        const item = getItem('toggle-' + this.tyyppi);
        if (_.isObject(item)) {
          return (item as any).toggled;
        }
      }
      return true;
    }
    catch (err) {
      return true;
    }
  }

  mounted() {
    this.toggled = this.tyyppi
      ? this.isToggled()
      : this.expandedByDefault;
  }

  toggle(toggle: boolean | null = null) {
    if (this.collapsable) {
      if (_.isNil(toggle)) {
        this.toggled = !this.toggled;
      }
      else {
        this.toggled = toggle;
      }
      if (this.tyyppi) {
        setItem('toggle-' + this.tyyppi, {
          toggled: this.toggled,
        });
      }
    }
  }
}

