
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpPerusteContent from '@shared/components/EpPerusteContent/EpPerusteContent.vue';
import OppiaineenVuosiluokkakokonaisuus from './OppiaineenVuosiluokkakokonaisuus.vue';
import { OpetussuunnitelmaDataStore } from '@/stores/OpetussuunnitelmaDataStore';
import { UnwrappedOpsOppiaineDtoTyyppiEnum } from '@shared/api/ylops';
import { Kielet } from '@shared/stores/kieli';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpAlert from '@shared/components/EpAlert/EpAlert.vue';
import { oppiaineenVuosiluokkakokonaisuudenRakennin } from './vuosiluokka';

@Component({
  components: {
    EpPerusteContent,
    OppiaineenVuosiluokkakokonaisuus,
    EpContentViewer,
    EpAlert,
  },
} as any)
export default class RouteOpetussuunnitelmaPerusopetusOppiaine extends Vue {
  @Prop({ required: true })
  private opetussuunnitelmaDataStore!: OpetussuunnitelmaDataStore;

  get kuvat() {
    return this.opetussuunnitelmaDataStore.kuvat;
  }

  get termit() {
    return this.opetussuunnitelmaDataStore.kaikkiTermit;
  }

  get vlkId() {
    return this.$route.params.vlkId;
  }

  get oppiaineId() {
    return _.toNumber(this.$route.params.oppiaineId);
  }

  get oppiaine() {
    return this.opetussuunnitelmaDataStore.getJulkaistuSisalto({ id: this.oppiaineId });
  }

  get perusteOppiaine() {
    return this.opetussuunnitelmaDataStore.getJulkaistuPerusteSisalto({ tunniste: this.oppiaine.tunniste });
  }

  get oppiaineenVuosiluokkakokonaisuudetSorted() {
    return _.sortBy(this.oppiaineenVuosiluokkakokonaisuudet, ovlk => this.$kaanna(ovlk.vuosiluokkakokonaisuus.nimi));
  }

  get oppiaineenVuosiluokkakokonaisuus() {
    return _.find(this.oppiaineenVuosiluokkakokonaisuudet, ovlk => _.toNumber(ovlk.vuosiluokkakokonaisuus.id) === _.toNumber(this.vlkId));
  }

  get opetussuunnitelmanVuosiluokkakokonaisuudet() {
    return this.opetussuunnitelmaDataStore.getJulkaistuSisalto('vuosiluokkakokonaisuudet');
  }

  get perusteenVuosiluokkakokonaisuudet() {
    return this.opetussuunnitelmaDataStore.getJulkaistuPerusteSisalto('perusopetus.vuosiluokkakokonaisuudet');
  }

  get perusteOppiaineVuosiluokkakokonaisuudet() {
    return _.map(this.perusteOppiaine.vuosiluokkakokonaisuudet, ovlk => {
      return {
        ...ovlk,
        tunniste: _.get(_.find(this.perusteenVuosiluokkakokonaisuudet, pvlk => _.toString(pvlk.id) === _.get(ovlk, '_vuosiluokkaKokonaisuus')), 'tunniste'),
      };
    });
  }

  get perusteOppiaineVapaatTekstit() {
    return _.map(this.perusteOppiaine.vapaatTekstit, povt => {
      return {
        ...povt,
        oppiaineVapaaTeksti: _.find(this.oppiaine.vapaatTekstit, ovt => _.toString(povt.id) === _.toString(ovt.perusteenVapaaTekstiId)) || {},
      };
    });
  }

  get oppiaineenPohjanVuosiluokkakokonaisuudet() {
    return this.oppiaine.pohjanOppiaine?.vuosiluokkakokonaisuudet;
  }

  get laajaalaisetOsaamiset() {
    return this.opetussuunnitelmaDataStore.getJulkaistuPerusteSisalto('perusopetus.laajaalaisetosaamiset');
  }

  get oppiaineenVuosiluokkakokonaisuudet() {
    const oppiaineenVlk = _.filter(this.oppiaine.vuosiluokkakokonaisuudet, vlk =>
      _.includes(_.map(this.opetussuunnitelmanVuosiluokkakokonaisuudet, 'vuosiluokkakokonaisuus._tunniste'), _.get(vlk, '_vuosiluokkakokonaisuus')));

    return _.map(oppiaineenVlk, (oppiaineenVuosiluokkakokonaisuus) => {
      const opetussuunnitelmanVuosiluokkakokonaisuus = _.get(_.find(this.opetussuunnitelmanVuosiluokkakokonaisuudet, vlk => _.get(vlk.vuosiluokkakokonaisuus, '_tunniste') === _.get(oppiaineenVuosiluokkakokonaisuus, '_vuosiluokkakokonaisuus')), 'vuosiluokkakokonaisuus');
      const perusteenVuosiluokkakokonaisuus = _.find(this.perusteenVuosiluokkakokonaisuudet, vlk => vlk.tunniste === (opetussuunnitelmanVuosiluokkakokonaisuus as any)._tunniste);

      if (this.oppiaine.tyyppi === _.toLower(UnwrappedOpsOppiaineDtoTyyppiEnum.YHTEINEN)) {
        const perusteenOppiaineenVlk = _.find(this.perusteOppiaineVuosiluokkakokonaisuudet, vlk => vlk.tunniste === (opetussuunnitelmanVuosiluokkakokonaisuus as any)._tunniste);
        const oppiaineenPohjanVuosiluokkakokonaisuus = _.find(this.oppiaineenPohjanVuosiluokkakokonaisuudet, ovlk => _.get(ovlk, '_vuosiluokkakokonaisuus') === _.get(opetussuunnitelmanVuosiluokkakokonaisuus, '_tunniste'));

        return oppiaineenVuosiluokkakokonaisuudenRakennin(
          this.oppiaine,
          this.perusteOppiaine,
          this.laajaalaisetOsaamiset,
          oppiaineenVuosiluokkakokonaisuus,
          opetussuunnitelmanVuosiluokkakokonaisuus,
          perusteenOppiaineenVlk,
          oppiaineenPohjanVuosiluokkakokonaisuus,
          perusteenVuosiluokkakokonaisuus,
        );
      }
      else {
        return {
          vuosiluokkakokonaisuus: opetussuunnitelmanVuosiluokkakokonaisuus,
          oppiaineenVuosiluokkakokonaisuus,
          oppiaine: this.oppiaine,
        };
      }
    });
  }
}
