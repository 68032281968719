
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NavigationNode } from '@shared/utils/NavigationBuilder';
import EpColorIndicator from '@shared/components/EpColorIndicator/EpColorIndicator.vue';
import EpNavigationPostFix from '@shared/components/EpTreeNavibar/EpNavigationPostFix.vue';
import EpSidenavNodeLabel from '@/components/EpSidenav/EpSidenavNodeLabel.vue';

@Component({
  name: 'EpSidenavNode',
  components: {
    EpColorIndicator,
    EpNavigationPostFix,
    EpSidenavNodeLabel,
  },
})
export default class EpSidenavNode extends Vue {
  @Prop({ required: true })
  node!: NavigationNode;

  @Prop({ required: false })
  current!: NavigationNode;

  @Prop({ required: false })
  getChildren!: Function;

  get children() {
    return this.getChildren(this.node);
  }

  get hasChildren() {
    return !_.isEmpty(this.children);
  }

  get isModuuli() {
    return this.node.type === 'moduuli' && typeof _.get(this.node, 'meta.pakollinen') === 'boolean';
  }

  get isKurssi() {
    return this.node.type === 'lukiokurssi' && _.get(this.node, 'meta.tyyppi');
  }

  get kurssiTyyppiVari() {
    return {
      'VALTAKUNNALLINEN_PAKOLLINEN': '#bddb8a',
      'PAKOLLINEN': '#bddb8a',
      'VALTAKUNNALLINEN_SYVENTAVA': '#997bb6',
      'VALTAKUNNALLINEN_SOVELTAVA': '#f8a35e',
    };
  }

  get isRoot() {
    return this.node.type === 'root';
  }

  get hasSeparator() {
    return this.node.meta && this.node.meta.post_separator;
  }

  get isMatch() {
    return this.node.isMatch;
  }

  get subtype() {
    return _.get(this.node.meta, 'navigation-subtype');
  }

  get koodi() {
    return _.get(this.node, 'meta.koodi.arvo') || _.get(this.node, 'meta.koodi');
  }
}
