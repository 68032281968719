
import * as _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TavoitteenSisaltoalueet extends Vue {
  @Prop()
  private sisaltoalueet!: any;

  @Prop({ default: true })
  private naytaSisaltoalueet!: boolean;

  @Prop({ default: true })
  private naytaOmaKuvaus!: boolean;
}
