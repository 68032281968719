
import * as _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { OpetussuunnitelmaDataStore } from '@/stores/OpetussuunnitelmaDataStore';
import OppiaineenVuosiluokka from './OppiaineenVuosiluokka.vue';
import OppiaineenVuosiluokkaTiivistetty from './OppiaineenVuosiluokkaTiivistetty.vue';
import { oppiaineenVuosiluokkakokonaisuudenRakennin } from './vuosiluokka';

@Component({
  components: {
    OppiaineenVuosiluokka,
    OppiaineenVuosiluokkaTiivistetty,
  },
})
export default class RouteTavoitteetSisallotArviointi extends Vue {
  @Prop({ required: true })
  private opetussuunnitelmaDataStore!: OpetussuunnitelmaDataStore;

  osiot: string[] = [];

  get vuosiluokka() {
    return this.$route.params.vuosiluokka;
  }

  set vuosiluokka(vuosiluokka) {
    this.$router.push({ params: { ...this.$route.params, vuosiluokka, oppiaineId: null } as any });
  }

  get oppiaine() {
    return this.$route.params.oppiaineId ? _.find(this.oppiaineetJaOppimaarat, { id: _.toNumber(this.$route.params.oppiaineId) }) : null;
  }

  set oppiaine(oppiaine) {
    this.$router.push({ params: { ...this.$route.params, oppiaineId: oppiaine?.id } });
  }

  selectOppiaine(oppiaine) {
    this.oppiaine = oppiaine;
  }

  get osioValinnat() {
    return ['sisaltoalueet', 'arviointikriteerit', 'laaja-alaisen-osaamisen-alueet'];
  }

  get naytaSisaltoalueet() {
    return this.osiot.length === 0 || this.osiot.includes('sisaltoalueet');
  }

  get naytaArviointikriteerit() {
    return this.osiot.length === 0 || this.osiot.includes('arviointikriteerit');
  }

  get naytaLaajaAlaisetOsaamiset() {
    return this.osiot.length === 0 || this.osiot.includes('laaja-alaisen-osaamisen-alueet');
  }

  get opetussuunnitelmanOppiaineet() {
    return this.opetussuunnitelmaDataStore.getJulkaistuSisalto('oppiaineet');
  }

  get perusteenOppiaineetByTunniste() {
    return _.chain(this.opetussuunnitelmaDataStore.getJulkaistuPerusteSisalto('perusopetus.oppiaineet'))
      .map(oppiaine => {
        return [
          oppiaine,
          ...(oppiaine.oppimaarat ? oppiaine.oppimaarat : []),
        ];
      })
      .flatten()
      .keyBy('tunniste')
      .value();
  }

  get oppiaineetJaOppimaarat() {
    if (this.vuosiluokka) {
      const oppiaineet = [..._.sortBy(_.sortBy(this.opetussuunnitelmanOppiaineet, opsoppiaine => this.$kaanna(opsoppiaine.oppiaine.nimi)), 'jnro')];
      return _.chain(oppiaineet)
        .map(opsoppiaine => {
          return [
            {
              ...opsoppiaine.oppiaine,
              jnro: opsoppiaine.jnro,
            },
            ..._.chain(opsoppiaine.oppiaine.oppimaarat)
              .map(oppimaara => {
                return {
                  ...oppimaara,
                  isOppimaara: true,
                };
              })
              .sortBy(oppimaara => this.$kaanna(oppimaara.nimi))
              .value(),
          ];
        })
        .flatten()
        .filter(oppiaine => _.find(oppiaine.vuosiluokkakokonaisuudet, vlk => _.find(vlk.vuosiluokat, { vuosiluokka: this.vuosiluokka }))
            || _.some(oppiaine.oppimaarat, oppimaara => _.find(oppimaara.vuosiluokkakokonaisuudet, vlk => _.find(vlk.vuosiluokat, { vuosiluokka: this.vuosiluokka }))))
        .value();
    }

    return [];
  }

  get oppiaineValinnat() {
    return _.map(this.oppiaineetJaOppimaarat, oppiaine => {
      return {
        ...oppiaine,
        $isDisabled: !_.find(this.oppiaineidenVuosiluokkienTavoitteet, { oppiaine: oppiaine })?.vuosiluokka,
      };
    });
  }

  get vuosiluokat() {
    return _.chain(_.map(this.opetussuunnitelmaDataStore.getJulkaistuSisalto('oppiaineet'), 'oppiaine'))
      .map('vuosiluokkakokonaisuudet')
      .flatMap()
      .filter(vlk => _.includes(_.map(this.opetussuunnitelmanVuosiluokkakokonaisuudet, 'vuosiluokkakokonaisuus._tunniste'), _.get(vlk, '_vuosiluokkakokonaisuus')))
      .map('vuosiluokat')
      .flatMap()
      .map('vuosiluokka')
      .flatMap()
      .uniq()
      .sort()
      .value();
  }

  get oppiaineenVuosiluokkakokonaisuus() {
    if (this.oppiaine && this.vuosiluokka) {
      return _.find(this.oppiaine.vuosiluokkakokonaisuudet, vlk => _.find(vlk.vuosiluokat, { vuosiluokka: this.vuosiluokka }));
    }
  }

  get oppiaineenVuosiluokka() {
    if (this.oppiaine && this.vuosiluokka) {
      return _.chain(this.oppiaine.vuosiluokkakokonaisuudet)
        .map('vuosiluokat')
        .flatMap()
        .find(vlk => vlk.vuosiluokka === this.vuosiluokka)
        .thru(vuosiluokka => this.oppiaineenVuosiluokkakokonaisuusPerusteDatoilla ? this.oppiaineenVuosiluokkakokonaisuusPerusteDatoilla[vuosiluokka.vuosiluokka] : vuosiluokka)
        .value();
    }
  }

  get oppiaineidenVuosiluokkienTavoitteet() {
    if (this.vuosiluokka) {
      return _.chain(this.oppiaineetJaOppimaarat)
        .map(oppiaine => {
          const perusteOppiaine = this.perusteenOppiaineetByTunniste[oppiaine.tunniste];
          const oppiaineenVlk = _.find(oppiaine.vuosiluokkakokonaisuudet, vlk => _.find(vlk.vuosiluokat, { vuosiluokka: this.vuosiluokka }));
          const opsVlk = this.findOpetussuunnitelmanVuosiluokkakokonaisuus(oppiaineenVlk);
          const perusteenOppiaineenVlk = this.findPerusteenOppiaineenVuosiluokkakokonaisuus(perusteOppiaine, opsVlk);
          const vlk = _.find(oppiaine.vuosiluokkakokonaisuudet, vlk => _.find(vlk.vuosiluokat, { vuosiluokka: this.vuosiluokka }));
          const perusteenVuosiluokkakokonaisuus = _.find(this.perusteenVuosiluokkakokonaisuudet, perusteVlk => _.get(perusteVlk, 'tunniste') === _.get(vlk, 'tunniste'));
          const oppiaineenPohjanVuosiluokkakokonaisuus = _.find(oppiaine?.pohjanOppiaine?.vuosiluokkakokonaisuudet, ovlk => _.get(ovlk, '_vuosiluokkakokonaisuus') === _.get(opsVlk, '_tunniste'));
          const oppiaineenVuosiluokkakokonaisuusDatat = oppiaineenVuosiluokkakokonaisuudenRakennin(
            oppiaine,
            perusteOppiaine,
            this.laajaalaisetOsaamiset,
            vlk,
            opsVlk,
            perusteenOppiaineenVlk,
            oppiaineenPohjanVuosiluokkakokonaisuus,
            perusteenVuosiluokkakokonaisuus,
          );
          const vuosiluokat = _.keyBy(_.get(oppiaineenVuosiluokkakokonaisuusDatat, 'oppiaineenVuosiluokkakokonaisuus.vuosiluokat'), 'vuosiluokka');
          return {
            oppiaine,
            vuosiluokka: vuosiluokat[this.vuosiluokka!],
          };
        })
        .filter(oppiaineJaTavoitteet => oppiaineJaTavoitteet.vuosiluokka)
        .value();
    }
  }

  get oppiaineenVuosiluokkakokonaisuusPerusteDatoilla() {
    if (this.oppiaine && this.vuosiluokka) {
      const oppiaineenVuosiluokkakokonaisuusPerusteDatoilla = oppiaineenVuosiluokkakokonaisuudenRakennin(
        this.oppiaine,
        this.perusteOppiaine,
        this.laajaalaisetOsaamiset,
        this.oppiaineenVuosiluokkakokonaisuus,
        this.opetussuunnitelmanVuosiluokkakokonaisuus,
        this.perusteenOppiaineenVuosiluokkakokonaisuus,
        this.oppiaineenPohjanVuosiluokkakokonaisuus,
        this.perusteenVuosiluokkakokonaisuus,
      );
      return _.keyBy(_.get(oppiaineenVuosiluokkakokonaisuusPerusteDatoilla, 'oppiaineenVuosiluokkakokonaisuus.vuosiluokat'), 'vuosiluokka');
    }

    return {};
  }

  get perusteOppiaine() {
    if (this.oppiaine) {
      return this.perusteenOppiaineetByTunniste[this.oppiaine.tunniste];
    }
  }

  get laajaalaisetOsaamiset() {
    return this.opetussuunnitelmaDataStore.getJulkaistuPerusteSisalto('perusopetus.laajaalaisetosaamiset');
  }

  get opetussuunnitelmanVuosiluokkakokonaisuudet() {
    return this.opetussuunnitelmaDataStore.getJulkaistuSisalto('vuosiluokkakokonaisuudet');
  }

  get oppiaineenPohjanVuosiluokkakokonaisuudet() {
    return this.oppiaine?.pohjanOppiaine?.vuosiluokkakokonaisuudet;
  }

  get opetussuunnitelmanVuosiluokkakokonaisuus() {
    return this.findOpetussuunnitelmanVuosiluokkakokonaisuus(this.oppiaineenVuosiluokkakokonaisuus);
  }

  findOpetussuunnitelmanVuosiluokkakokonaisuus(oppiaineenVuosiluokkakokonaisuus) {
    return _.get(_.find(this.opetussuunnitelmanVuosiluokkakokonaisuudet, vlk => _.get(vlk.vuosiluokkakokonaisuus, '_tunniste') === _.get(oppiaineenVuosiluokkakokonaisuus, '_vuosiluokkakokonaisuus')), 'vuosiluokkakokonaisuus');
  }

  get perusteOppiaineVuosiluokkakokonaisuudet() {
    return this.findPerusteOppiaineenVuosiluokkakokonaisuudet(this.perusteOppiaine);
  }

  findPerusteOppiaineenVuosiluokkakokonaisuudet(perusteOppiaine) {
    return _.map(perusteOppiaine?.vuosiluokkakokonaisuudet, ovlk => {
      return {
        ...ovlk,
        tunniste: _.get(_.find(this.perusteenVuosiluokkakokonaisuudet, pvlk => _.toString(pvlk.id) === _.get(ovlk, '_vuosiluokkaKokonaisuus')), 'tunniste'),
      };
    });
  }

  get perusteenVuosiluokkakokonaisuudet() {
    return this.opetussuunnitelmaDataStore.getJulkaistuPerusteSisalto('perusopetus.vuosiluokkakokonaisuudet');
  }

  get perusteenVuosiluokkakokonaisuus() {
    return _.find(this.perusteenVuosiluokkakokonaisuudet, vlk => _.get(vlk, 'tunniste') === _.get(this.oppiaineenVuosiluokkakokonaisuus, '_vuosiluokkakokonaisuus'));
  }

  get perusteenOppiaineenVuosiluokkakokonaisuus() {
    return this.findPerusteenOppiaineenVuosiluokkakokonaisuus(this.perusteOppiaine, this.opetussuunnitelmanVuosiluokkakokonaisuus);
  }

  findPerusteenOppiaineenVuosiluokkakokonaisuus(perusteOppiaine, vuosiluokkakokonaisuus) {
    return _.find(this.findPerusteOppiaineenVuosiluokkakokonaisuudet(perusteOppiaine), vlk => vlk?.tunniste === (vuosiluokkakokonaisuus as any)?._tunniste);
  }

  get oppiaineenPohjanVuosiluokkakokonaisuus() {
    return _.find(this.oppiaineenPohjanVuosiluokkakokonaisuudet, ovlk => _.get(ovlk, '_vuosiluokkakokonaisuus') === _.get(this.opetussuunnitelmanVuosiluokkakokonaisuus, '_tunniste'));
  }

  searchIdentity(oppiaine: any) {
    return _.toLower(this.$kaanna(oppiaine.nimi) as any);
  }

  get kuvat() {
    return this.opetussuunnitelmaDataStore.kuvat;
  }

  get termit() {
    return this.opetussuunnitelmaDataStore.kaikkiTermit;
  }
}
