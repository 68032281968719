import Vue from 'vue';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';
import EpToggle from '@shared/components/forms/EpToggle.vue';
import EpPdfLink from '@shared/components/EpPdfLink/EpPdfLink.vue';
import EpDatepicker from '@shared/components/forms/EpDatepicker.vue';
import EpMultiSelect from '@shared/components/forms/EpMultiSelect.vue';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpExternalLink from '@shared/components/EpExternalLink/EpExternalLink.vue';
import EpInfoBanner from '@shared/components/EpInfoBanner/EpInfoBanner.vue';
import EpCollapse from '@shared/components/EpCollapse/EpCollapse.vue';
import EpPaikallinenTarkennus from '@shared/components/EpPaikallinenTarkennus/EpPaikallinenTarkennus.vue';

Vue.component('EpMaterialIcon', EpMaterialIcon);
Vue.component('EpToggle', EpToggle);
Vue.component('EpPdfLink', EpPdfLink);
Vue.component('EpDatepicker', EpDatepicker);
Vue.component('EpMultiSelect', EpMultiSelect);
Vue.component('EpFormContent', EpFormContent);
Vue.component('EpButton', EpButton);
Vue.component('EpSpinner', EpSpinner);
Vue.component('EpExternalLink', EpExternalLink);
Vue.component('EpInfoBanner', EpInfoBanner);
Vue.component('EpCollapse', EpCollapse);
Vue.component('EpPaikallinenTarkennus', EpPaikallinenTarkennus);
